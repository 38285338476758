import { AxiosResponse } from 'axios';
import request from '../request';
import { IPaginatedPaylod, IPaginatedResponse } from '../../../../shared/interfaces/request.interface';
import { IUsersPayload, IUsersResponse } from '../../../../shared/interfaces/user.interface';
import { IUsersBusinessUnitsResponse } from '../../../../shared/interfaces/businessUnits.interface';
import { IUserInvite } from '../../../../shared/interfaces/invite.interface';
import { IUsersCompaniesBenefitsResponse } from '../../../../shared/interfaces/companiesBenefits.interface';

const getAll = async ({
  companyId,
  role,
  searchTerm,
  itemsPerPage = 10,
  page = 1,
}: IPaginatedPaylod & IUsersPayload): Promise<AxiosResponse<IPaginatedResponse<IUsersResponse>>> => {
  const config = {
    params: {
      companyId,
      searchTerm,
      role,

      itemsPerPage,
      page,
    },
  };
  return request.get('/users', config);
};

const getById = async (id) => {
  return request.get(`/users/id/${id}`);
};

const create = async (data) => {
  return request.post('/users', data);
};

const update = async (userId: string, body) => request.put(`/users/id/${userId}`, body);

const updatePass = async (userId: string, body) => request.put(`/users/${userId}/pass-by-admin`, body);

const destroy = async (user) => request.put(`/users/id/${user.id}`, user);

const getAllBusinessUnits = async (
  rootCompanyId: string,
  { itemsPerPage, page, corporateName }: IPaginatedPaylod & { corporateName: string },
): Promise<AxiosResponse<IPaginatedResponse<IUsersBusinessUnitsResponse>>> => {
  const config = {
    params: {
      corporateName,
      itemsPerPage,
      page,
    },
  };

  return request.get(`/users/business-units/${rootCompanyId}`, config);
};

const getInviteByCompanyId = async (companyId: string, { email }: { email: string }): Promise<AxiosResponse<IUserInvite>> => {
  const config = {
    params: {
      email,
    },
  };

  return request.get(`/users/invites/${companyId}`, config);
};
const getAllCompaniesBenefits = async ({
  corporateName,
  itemsPerPage,
  page,
}: IPaginatedPaylod & { corporateName?: string }): Promise<AxiosResponse<IPaginatedResponse<IUsersCompaniesBenefitsResponse>>> => {
  const config = {
    params: {
      corporateName,
      itemsPerPage,
      page,
    },
  };
  return request.get(`/users/companies/benefits`, config);
};

export default {
  create,
  getAll,
  update,
  updatePass,
  destroy,
  getById,
  getAllBusinessUnits,
  getInviteByCompanyId,
  getAllCompaniesBenefits,
};
