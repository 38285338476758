import axios from 'axios';
import { getAccessToken, clearAuthCookies } from './cookiesHelper';

const BASE_URL: string | undefined = process.env.BV_API_URL;

const request = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

request.interceptors.request.use(function (config) {
  const ACCESS_TOKEN: string | null = getAccessToken();

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  return config;
});

request.interceptors.response.use(null, function (err) {
  if (err.response && [401, 403].includes(+err.response.status)) {
    const userViewType = JSON.parse(window.localStorage.getItem('userViewType'));
    const prescriptionUser = JSON.parse(window.localStorage.getItem('prescriptionUser'));
    if (userViewType === 'patient' && prescriptionUser?.payload?.redirectUrl) {
      clearAuthCookies();
      window.localStorage.clear();
      window.location.href = prescriptionUser?.payload?.redirectUrl;
    } else {
      clearAuthCookies();
      window.localStorage.clear();
      window.location.href = '/login';
    }
  } else if (err.response && err.response.status === 400 && err.response && err.response.data) {
    return Promise.reject(err.response.data);
  } else if (err.response && err.response.status === 422 && err.response.data && err.response.data.errors) {
    const errors = Object.keys(err.response.data.errors).reduce((acc, key) => {
      acc = acc.concat(err.response.data.errors[key]);
      return acc;
    }, []);
    return Promise.reject({ message: errors.join(' | ') });
  } else if (err.response.status === 406 && err.config.url.indexOf('/pendencies/prescription-renewal') > -1) {
    return Promise.reject({ status: 406, message: 'Dados da receita incompletos, por favor, preencha os campos obrigatórios.' });
  }

  return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
});

export default request;
