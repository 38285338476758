import { Invite } from "../../../../shared/interfaces/invite.interface";
import request from "../request";
import { AxiosResponse } from "axios";

// const getAll = async (search: string, page: number, itemsPerPage = 10) => {
const getAll = async ({
  searchTerm = undefined,
  page = 1,
  itemsPerPage = 10,
  role = undefined,
  status = undefined,
  fields = undefined,
  companyId = undefined,
}) => {
  const config = {
    params: {
      searchTerm,
      page,
      itemsPerPage: itemsPerPage,
      role,
      status,
      companyId,
      fields,
    },
  };
  return request.get("/invites", config);
};

const getById = async (inviteId): Promise<AxiosResponse<Invite>> => {
  return request.get(`invites/${inviteId}`);
};
const getStatistics = (companyId: string = undefined) => {
  const config = {
    params: { companyId },
  };

  return request.get(`/invites/statistics`, config);
};

const create = async (data: any) => {
  return request.post("/invites", data);
};
const resend = async (id: string) => {
  return request.post(`/invites/${id}/resend`);
};

const update = async (id: string, data: any) => {
  return request.put(`/invites/${id}`, data);
};

const destroy = async (id: string) => {
  return request.delete(`/invites/${id}`);
};
const bulkInsert = async (data, companyId?: string) => {
  return request.post("/bulk-insert", data, {
    params: { companyId },
  });
};

const getInviteByEmail = async (
  email: string
): Promise<AxiosResponse<Invite>> => {
  return request.get(`/invites/email/${email}`);
};

export default {
  getAll,
  getById,
  create,
  update,
  destroy,
  bulkInsert,
  getStatistics,
  resend,
  getInviteByEmail,
};
